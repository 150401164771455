<template>
    <div class="container">
        <p class="title" style="font-size: 28px; margin-bottom: 10px;">2022新春快乐、万事如意</p>
        <p class="time" style="font-size: 18px; margin-bottom: 20px;">2022-01-27 09:30</p>
        <div class="text" style="margin-bottom: 25px;">
            <p>春节是中国最古老的节日，是一年四季中最隆重的日子。尽管我们民族众多，幅员辽阔，但是千百年以来，还是逐渐形成了较为固定的风俗习惯。</p>
            <p>春节是我们一切感情的归宿，春节是我们所有哲学的基础。有了春节，生活不再乏味，人生有了意义。有了春节，回家有了理由，喝酒有了主题。</p>
            <p>快乐烟花开满天，盛装舞步过新年，吉庆有余春常在，幸福依旧伴身边。放下工作享清闲，丢下烦恼换笑颜，福禄寿喜全来到，生活美满似蜜甜。新春佳节喜团圆，好运降临合家欢，开心喧闹贺新春，高高兴兴过大年!</p>
        </div>
        <img src="../../static/img/consultCenterImg/home2.png" style="width: 60vw; margin: auto; display: block; margin-bottom: 25px;" alt="">
        <div class="text" style="margin-bottom: 25px;">
            <p>    2022，是2021幸福的延续，2021，是2022美满的前提；2021，是2022缘分的开花，是2022结果的前提；2021，是2022努力的继续，是2022成功的前提！回望过去的一年，付出了汗水，付出了努力，收获了喜悦，收获了成功，在五彩斑斓的人生涂上了重重一笔;新的一年到来，愿你执起勤奋的笔，加油。</p>
            <p>回看2021，不要在乎失去了谁，要去珍惜剩下的是谁，无论是谁，你若不离我便不弃！2021已靠岸，2022正启航，让我们继续装满健康、平安、幸福、快乐！扬起的2022的风帆，重新为梦掌舵，重新怀梦飞扬，愿你新年更成功，生活更快乐。顺风顺水，一路前行！</p>
        </div>
        <img src="../../static/img/consultCenterImg/news-2/1.png" style="height: 40vw; margin: auto; display: block; margin-bottom: 35px;" alt="">
        <img src="../../static/img/consultCenterImg/news-2/2.png" style="height: 20vw; margin: auto; display: block; margin-bottom: 35px;" alt="">
        <p class="text" style="margin-bottom: 25px;">#迎新年、送福气#，新春送礼包，祝愿宝德仕伙伴们在新的一年里，工作顺利、阖家幸福！<br> 公司为全休职工发放虎年春节礼包，送上节日祝福</p>
        <div class="grid">
            <img src="../../static/img/consultCenterImg/news-2/3.png" style="width: 80%; display: block; margin: auto;" alt="">
            <img src="../../static/img/consultCenterImg/news-2/4.png" style="width: 80%; display: block; margin: auto;" alt="">
            <img src="../../static/img/consultCenterImg/news-2/5.png" style="width: 80%; display: block; margin: auto;" alt="">
            <img src="../../static/img/consultCenterImg/news-2/6.png" style="width: 80%; display: block; margin: auto;" alt="">
        </div>
        <p class="text" style="margin-bottom: 25px;">工会成员福利，节日现金红包噢~~ <br> 请大家踊跃加入工会组织，成为其中一员！</p>
        <div class="grid" style="grid-template-rows: 1fr;">
            <img src="../../static/img/consultCenterImg/news-2/7.png" style="width: 80%; display: block; margin: auto;" alt="">
            <img src="../../static/img/consultCenterImg/news-2/8.png" style="width: 80%; display: block; margin: auto;" alt="">
        </div>
    </div>
</template>
<script>
export default {created() {
      this.isActive = this.$route.params.index;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0
  },
}
</script>
<style scoped>
    * {
    padding: 0;
    box-sizing: border-box;
    margin: 0;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    color: #000000;
    }

    .title, .time{
        display: block;
        margin: auto;
        text-align: center;
    }

    .text{
        font-size: 16px;
        text-indent: 2em;text-align: center;
    }

    .grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-column-gap: 0;
        grid-row-gap: 20px;
        width: 90%;
        margin: auto;
        margin-bottom: 25px;
    }
</style>